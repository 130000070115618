import React, { Component } from "react";

export default class Main extends Component {
  constructor() {
    super();
    this.state = {
      serverAddress: "",
      projects: [],
    };
    this.getFrontends = this.getFrontends.bind(this);
  }

  async componentDidMount() {
    try {
      let serverAddress = `${window.location.protocol}//${window.location.host}`;
      this.setState({
        serverAddress,
      });
    } catch (e) {
      console.log(e);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.serverAddress !== nextState.serverAddress) {
      this.getFrontends(nextState.serverAddress);
    }
    return true;
  }

  async getFrontends(serverAddress) {
    let projects = [];
    try {
      let data = await fetch(`${serverAddress}/api/projects`);
      projects = await data.json();
    } catch (e) {
      console.log(e);
    }
    this.setState({ projects });
  }

  render() {
    return (
      <div>
        {this.state.projects.map((project) => {
          if (project.Name !== "STATIC") {
            let frontends = project.Frontends.map((frontend) => {
              if (frontend.split("-")[1] !== "data") {
                return (
                  <div key={frontend}>
                    <a
                      target="_blank"
                      href={`${frontend}/index.html`}
                      className="btn btn-primary m-1"
                    >
                      {frontend}
                    </a>
                  </div>
                );
              }
            });
            let projectRendered = (
              <div key={project}>
                <h2>{project.Name}</h2>
                {frontends}
              </div>
            );
            return projectRendered;
          }
          return "";
        })}
      </div>
    );
  }
}
